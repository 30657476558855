import React, { createContext, useReducer, useEffect, useContext } from "react";

import Cookies from "js-cookie";
import Swal from "sweetalert2";

import dal from '../dal/api-call';
// import { Scenario2Context } from "../../../../context/scenario2/Scenario2Context"
//https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/1?region=Africa&country=All&date=2022-03-08&limit=500&offset=0
import { FilterContext } from "./FilterContext";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
  tab1: [],
  tab2: [],
  isFetching: false,
  isFetchingTab1: false,
  isErrorTab1: false,
  isFetchingTab2: false,
  isErrorTab2: false,
  isSubmitClicked: false,
  totalRecordsTab1: 0,
  totalRecordsTab2: 0,
  batchSizeTab1: 0,
  batchSizeTab2: 0,
  columnTab1: [],
  columnTab2: [],
  isTab1Fetched: false,
  isTab2Fetched: false,
  errorMessage: "",
};

let reducer = (state, action) => {
  let code = Cookies.get("code");
  // code = "1234"
  // console.log("API CALL CODE from 10 context:::", code);
  // console.log(action)
  switch (action.type) {
    case "HANDLE_SUBMIT":

      fetchTab1APiData(action.filterState, action.dispatch);

      fetchTab2APiData(action.filterState, action.dispatch);

      return {
        ...state,
        isFetchingTab1: true,
        isFetchingTab2: true,
      };

    case "FETCH_DATA":
      // if (code) {
      //   let temp = state.tab1;
      //   // console.log(temp)
      //   temp.push(...action.payload.data);

      //   return {
      //     ...state,
      //     tab1: [...new Set(temp)],
      //     isFetchingTab1: false,
      //     totalRecordsTab1: action.payload.totalRecords,
      //     batchSizeTab1: action.payload.batchSize,
      //     columnTab1: action.payload.columns,
      //   };
      // } else {
      //   sweetAlert();
      // }
      let temp = state.tab1;
      // console.log(temp)
      temp.push(...action.payload.data);

      return {
        ...state,
        tab1: [...new Set(temp)],
        isFetchingTab1: false,
        totalRecordsTab1: action.payload.totalRecords,
        batchSizeTab1: action.payload.batchSize,
        columnTab1: action.payload.columns,
      };

    case "FETCH_TAB2_DATA":
      // if (code) {
      //   // console.log("SC2 Tab2 :::", action.payload.data)
      //   let temp2 = state.tab2;
      //   // console.log(temp)
      //   temp2.push(...action.payload.data);
      //   return {
      //     ...state,
      //     tab2: [...new Set(temp2)],
      //     isFetchingTab2: false,
      //     totalRecordsTab2: action.payload.totalRecords,
      //     batchSizeTab2: action.payload.batchSize,
      //     columnTab2: action.payload.columns,
      //   };
      // } else {
      //   sweetAlert();
      // }
      let temp2 = state.tab2;
      // console.log(temp)
      temp2.push(...action.payload.data);
      return {
        ...state,
        tab2: [...new Set(temp2)],
        isFetchingTab2: false,
        totalRecordsTab2: action.payload.totalRecords,
        batchSizeTab2: action.payload.batchSize,
        columnTab2: action.payload.columns,
      };

    case "FETCH_ERROR_TAB1":
      console.log("FETCH_ERROR_TAB1 :: ", action.error);
      return {
        ...state,
        isErrorTab1: true,
        isFetchingTab1: false,
        isTab1Fetched: false,
        tab1: [],
        columnTab1: [],
        totalRecordsTab1: 0,
        errorMessage: action.error,
      };
    case "FETCH_ERROR_TAB2":
      console.log("FETCH_ERROR_TAB2 :: ", action.error);
      return {
        ...state,
        isErrorTab2: true,
        isFetchingTab2: false,
        isTab2Fetched: false,
        tab2: [],
        columnTab2: [],
        totalRecordsTab2: 0,
        errorMessage: action.error,
      };

    case "RESET_TAB1":
      return {
        ...state,
        isErrorTab1: false,
        isFetchingTab1: false,
        isTab1Fetched: false,
        tab1: [],
        totalRecordsTab1: 0,
        batchSizeTab1: 0,
        columnTab1: [],
      };
    case "RESET_TAB2":
      return {
        ...state,
        isErrorTab2: false,
        isFetchingTab2: false,
        isTab2Fetched: false,
        tab2: [],
        totalRecordsTab2: 0,
        batchSizeTab2: 0,
        columnTab2: [],
      };

    case "TAB1_STATUS":
      return {
        ...state,
        isTab1Fetched: action.status,
      };
    case "TAB2_STATUS":
      return {
        ...state,
        isTab2Fetched: action.status,
      };
    default:
      return;
  }
};

const sweetAlert = () => {
  Swal.fire({
    title: "Session has expired",
    text: "Redirecting to the login page",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Ok",
    cancelButtonText: "Cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = `/`;
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      // window.location.href = `/login`;
    }
  });
};

export const Scenario10Context = createContext(INITIAL_STATE);

export const Scenario10Provider = (props) => {
  const { filterState } = useContext(FilterContext);

  const [sc10State, sc10Dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Scenario10Context.Provider value={{ sc10State, sc10Dispatch }}>
      {props.children}
    </Scenario10Context.Provider>
  );
};

const fetchTab1APiData = async (filterState, dispatch) => {
  offset = 0;
  try { 
    const response = await dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc10/1?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    // const response = await dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/1?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    // let result = response.data;
    // console.log("fetchTab1APiData data:::", response);
    let result ;
    if (response.status == 200){
      result = response.data;

      dispatch({ type: "RESET_TAB1" });
      dispatch({ type: "FETCH_DATA", payload: result });

      let offsetCounter = 0;
      if (offset <= result.totalRecords) {

        while (offsetCounter <= result.batchSize) {

          offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
          offset = offsetCounter * limit;

          const response = await dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc10/1?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
          // const response = await dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/1?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
          // console.log("fetchTab1APiData response :: ", response);
          if(response.status == 200){
            const result = response.data;

            dispatch({ type: "FETCH_DATA", payload: result });
            offsetCounter = offsetCounter + 1;
          }else{
            // console.log("fetchTab1APiData error 1 :: ", response)
            dispatch({ type: "FETCH_ERROR_TAB1", error: response.data.message });
          }

        }
        if (offsetCounter > result.batchSize) {
          dispatch({ type: "TAB1_STATUS", status: true });
        }
      } else {
        dispatch({ type: "TAB1_STATUS", status: true });
      }

    }else{
      // console.log("fetchTab1APiData error :: ",response);
      dispatch({ type: "FETCH_ERROR_TAB1", error: response.data.message });
    }

    // let offsetCounter = 0;
    // if (offset <= result.totalRecords) {

    //   while (offsetCounter <= result.batchSize) {

    //     offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
    //     offset = offsetCounter * limit;

    //     const response = await dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}sc10/1?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    //     // const response = await dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/1?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    //     console.log("fetchTab1APiData response :: ", response);
    //     if(response.status == 200){
    //       const result = response.data;

    //       dispatch({ type: "FETCH_DATA", payload: result });
    //       offsetCounter = offsetCounter + 1;
    //     }else{
    //       console.log("fetchTab1APiData error 1 :: ", response)
    //       dispatch({ type: "FETCH_ERROR_TAB1", error: response.data.message });
    //     }

    //   }
    //   if (offsetCounter > result.batchSize) {
    //     dispatch({ type: "TAB1_STATUS", status: true });
    //   }
    // } else {
    //   dispatch({ type: "TAB1_STATUS", status: true });
    // }

    return result;
  } catch (error) {
    // console.log("fetchTab1APiData errror::", error);
    dispatch({ type: "FETCH_ERROR_TAB1", error: error.response.data.message });
  }
};

const fetchTab2APiData = async (filterState, dispatch) => {

  offset = 0;

  try {

    const response = await dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc10/2?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    // const response = await dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/2?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    let result;
    if (response.status == 200){
      result = response.data;

      dispatch({ type: "RESET_TAB2" });
      dispatch({ type: "FETCH_TAB2_DATA", payload: result });

      let offsetCounter = 0;
      if (offset <= result.totalRecords) {

        while (offsetCounter <= result.batchSize) {

          offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
          offset = offsetCounter * limit;

          const response = await dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc10/2?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`);
          // const response = await dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/2?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
          // console.log("fetchTab2APiData response :: ", response);
          if(response.status == 200){
            const result = response.data;

            dispatch({ type: "FETCH_TAB2_DATA", payload: result });
            offsetCounter = offsetCounter + 1;
          }else{
            // console.log("fetchTab2APiData error 1 :: ", JSON.stringify(response));
            dispatch({ type: "FETCH_ERROR_TAB2", error: response.data.message });
          }
        }
        if (offsetCounter > result.batchSize) {
          dispatch({ type: "TAB2_STATUS", status: true });
        }
      } else {
        dispatch({ type: "TAB2_STATUS", status: true });
      }

    }else{
      // console.log("fetchTab2APiData error :: ",response.data.message);
      dispatch({ type: "FETCH_ERROR_TAB2", error: response.data.message });
    }
    
    // let result = response.data;
    // dispatch({ type: "RESET_TAB2" });
    // dispatch({ type: "FETCH_TAB2_DATA", payload: result });

    // let offsetCounter = 0;
    // if (offset <= result.totalRecords) {

    //   while (offsetCounter <= result.batchSize) {

    //     offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
    //     offset = offsetCounter * limit;

    //     const response = await dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}sc10/2?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`);
    //     // const response = await dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/sc10/2?region=${filterState.geo}&country=${filterState.country}&date=${filterState.date}&limit=${limit}&offset=${offset}`)
    //     console.log("fetchTab2APiData response :: ", response);
    //     if(response.status == 200){
    //       const result = response.data;

    //       dispatch({ type: "FETCH_TAB2_DATA", payload: result });
    //       offsetCounter = offsetCounter + 1;
    //     }else{
    //       console.log("fetchTab2APiData error 1 :: ", JSON.stringify(response));
    //       dispatch({ type: "FETCH_ERROR_TAB2", error: response.data.message });
    //     }
    //   }
    //   if (offsetCounter > result.batchSize) {
    //     dispatch({ type: "TAB2_STATUS", status: true });
    //   }
    // } else {
    //   dispatch({ type: "TAB2_STATUS", status: true });
    // }

    return result;
  } catch (error) {
    // console.log("fetchTab2APiData errror::", error);
    dispatch({ type: "FETCH_ERROR_TAB2", error: error.response.data.message });
  }
};
