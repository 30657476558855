import moment from "moment";
import Cookies from "js-cookie";

export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

export function generateDateObject(selectedValue, isCustomDateFormat = false) {
    //generate from and to

    const formatter = "YYYY-MM-DD hh:mm:ss";
    let stamp = {};
    if (isCustomDateFormat) {

        stamp = {
            selectedValue: 'custom',
            timeStamp: {
                from: moment(selectedValue[0]).format(formatter),
                to: moment(selectedValue[1]).format(formatter)
            }
        }
    } else {
        let value = selectedValue;
        let selectedValueArray = value.split("-");
        stamp = {
            selectedValue,
            timeStamp: {
                from: moment().subtract(selectedValueArray[0], selectedValueArray[1]).format(formatter),
                to: moment().format(formatter)
            }//add autoincrement id alos
            // autoIncrementedId: 10
        }
    }
    return stamp;
}

export function setExpiration(expirationInMin = 15) {

    let code = Cookies.get('code');
    let expiresIn = Cookies.get('expiresIn');

    let isCookieExpiryTime = (expiresIn - new Date().getTime()) / 60000

    if (isNaN(isCookieExpiryTime)) return false;
    if (isCookieExpiryTime < 2) {
        let expires = new Date(new Date().getTime() + expirationInMin * 60 * 1000)
        Cookies.set('code', code, {
            expires
        });
        Cookies.set('expiresIn', new Date().getTime(), {
            expires
        });
    }

    return true;





    // let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin)).getTime()

    // localStorage.setItem('expires_in', expirationDate)
}


//Encrypt a String with SHA256
export async function encryptStringWithSHA256(str) {
    const PROTOCOL = 'SHA-256'
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest(PROTOCOL, encodedData);
}


export function getRandomString() {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).substr(-2)}`)
    return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
}

//Convert Hash to Base64-URL
export function hashToBase64url(arrayBuffer) {
    const items = new Uint8Array(arrayBuffer)
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
    const decodedHash = btoa(stringifiedArrayHash)

    const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URL
}

export function getCommonElements(array1, array2) {
    return array1.filter(value => array2.includes(value));
}

export function getURL(url) {
    let baseURL = '';
    if(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/`) {
        baseURL = `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/`;
    }
    else {
        baseURL = `https://compliance.dev.api.pe.tvx-test.cloud/ui/scenario/`;
    }
    
    let urlToBePassed = baseURL + url;
    return new URL(urlToBePassed);
}

export function sleep(delay) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, delay);
    })
}