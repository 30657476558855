import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom"

import Topbar from "./components/Layout/Topbar";
import Footer from "./components/Layout/Footer/Footer";
// import dal from "./dal/api-call";

// import { HomeLayout } from "./components/Layout/Home/Home"
// import Scenario1n3 from "./components/Layout/TRScenarios/Scenario1n3/Scenario1n3"
// import Scenario2 from "./components/Layout/TRScenarios/Scenario2/Scenario2"
// import Scenario4n9 from "./components/Layout/TRScenarios/Scenario4n9/Scenario4n9"
// import Scenario5 from "./components/Layout/TRScenarios/Scenario5/Scenario5"
// import Scenario6n7 from "./components/Layout/TRScenarios/Scenario6n7/Scenario6n7"
// import Scenario8 from "./components/Layout/TRScenarios/Scenario8/Scenario8"
import Scenario10 from "./components/Layout/TRScenarios/Scenario10/Scenario10"
// import Scenario10 from "./components/Layout/TRScenarios/Scenario10"

import { FilterProvider } from './context/FilterContext';
// import { Scenario2Provider } from './context/Scenario2Context'
// import { Scenario1n3Provider } from "./context/Scenario1n3Context"
// import { Scenario6n7Provider } from "./context/Scenario6n7Context"
// import { Scenario8Provider } from "./context/Scenario8Context"
// import { Scenario4n9Provider } from "./context/Scenario4n9Context"
// import { Scenario5Provider } from "./context/Scenario5Context"
import { Scenario10Provider } from "./context/Scenario10Context"

let userIdentity = null;

const home = () => {
  
  return (
    <>
      <Col style={{display:"flex", justifyContent:"center" }}>
        <span style={{width: "230px", border: "3px solid grey"}}><i>Transaction Review Home Page</i></span>
      </Col>
    </>
  )
}

function App() {
  return (
    <Container fluid>
      <BrowserRouter>
        <FilterProvider>
          <Row>
            <Topbar 
              userIdentity={userIdentity}
            />
          </Row>
          <Row>
            <Switch>
              <Route path="/dashboard" component={home} exact={true} />
              {/* <Route path="/" exact={true} render={(params) => <HomeLayout />} /> */}
              {/* <Route exact path="/sc1_3"
                render={(params) => <Scenario1n3Provider><Scenario1n3 /></Scenario1n3Provider>}
              />
              <Route exact path="/sc2" 
              render={(params) => <Scenario2Provider><Scenario2/></Scenario2Provider> }
              />
              <Route
                exact path="/sc4_9"
                render={(params) => <Scenario4n9Provider><Scenario4n9 /> </Scenario4n9Provider>}
              />
              <Route
                exact path="/sc5"
                render={(params) => <Scenario5Provider><Scenario5 /></Scenario5Provider>}
              />
              <Route
                exact path="/sc6_7"
                render={(params) => <Scenario6n7Provider><Scenario6n7 /></Scenario6n7Provider>}
              />
              <Route
                exact path="/sc8"
                render={(params) => <Scenario8Provider><Scenario8 /></Scenario8Provider>}
              /> */}
              {/* {userIdentity ? (
                <Route 
                  exact path="/sc10"
                  render={(params) => <Scenario10Provider><Scenario10 /></Scenario10Provider>}
                />
              ): (console.log("Empty user identity"))} */}
               <Route 
                 exact path="/sc10"
                 render={(params) => <Scenario10Provider><Scenario10 /></Scenario10Provider>}
               />
            </Switch>
          </Row>
          <Row>
            <Footer />
          </Row>
        </FilterProvider>
      </BrowserRouter>
    </Container>
  );
}

export default App;
