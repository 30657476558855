import React, { createContext, useReducer, useEffect, useContext } from "react";
import axios from "axios";

// import { Scenario2Context } from "../../../../context/scenario2/Scenario2Context"

import { FilterContext } from "./FilterContext"
import { message } from "antd";
import { act } from "react-dom/test-utils";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
    tab5: [],
    tab6: [],
    tab7: [],
    tab8: [],
    tab9: [],
    isFetchingTab1: false,
    isErrorTab1: false,
    isFetchingTab2: false,
    isErrorTab2: false,
    isFetchingTab3: false,
    isErrorTab3: false,
    isFetchingTab4: false,
    isErrorTab4: false,
    isFetchingTab5: false,
    isErrorTab5: false,
    isFetchingTab6: false,
    isErrorTab6: false,
    isFetchingTab7: false,
    isErrorTab7: false,
    isFetchingTab8: false,
    isErrorTab8: false,
    isFetchingTab9: false,
    isErrorTab9: false,
    isFetchingTab10: false,
    isErrorTab10: false,
    isSubmitClicked: false,
    totalRecordsTab1: 0,
    totalRecordsTab2: 0,
    totalRecordsTab3: 0,
    totalRecordsTab4: 0,
    totalRecordsTab5: 0,
    totalRecordsTab6: 0,
    totalRecordsTab7: 0,
    totalRecordsTab8: 0,
    totalRecordsTab9: 0,
    totalRecordsTab10: 0,
    batchSizeTab1: 0,
    batchSizeTab2: 0,
    batchSizeTab3: 0,
    batchSizeTab4: 0,
    batchSizeTab5: 0,
    batchSizeTab6: 0,
    batchSizeTab7: 0,
    batchSizeTab8: 0,
    batchSizeTab9: 0,
    batchSizeTab10: 0,
    columnTab1: [],
    columnTab2: [],
    columnTab3: [],
    columnTab4: [],
    columnTab5: [],
    columnTab6: [],
    columnTab7: [],
    columnTab8: [],
    columnTab9: [],
    columnTab10: [],
    isTab1Fetched: false,
    isTab2Fetched: false,
    isTab3Fetched: false,
    isTab4Fetched: false,
    isTab5Fetched: false,
    isTab6Fetched: false,
    isTab7Fetched: false,
    isTab8Fetched: false,
    isTab9Fetched: false,
    isTab10Fetched: false,
    errorMessage: "",
};

let reducer = (state, action) => {
    // console.log(action)
    switch (action.type) {
        case "HANDLE_SUBMIT":
            fetchTab1APiData(action.filterState, action.dispatch)

            fetchTab2APiData(action.filterState, action.dispatch)

            fetchTab3APiData(action.filterState, action.dispatch)

            fetchTab4APiData(action.filterState, action.dispatch)

            fetchTab5APiData(action.filterState, action.dispatch)

            fetchTab6APiData(action.filterState, action.dispatch)

            fetchTab7APiData(action.filterState, action.dispatch)

            fetchTab8APiData(action.filterState, action.dispatch)

            fetchTab9APiData(action.filterState, action.dispatch)

            fetchTab10APiData(action.filterState, action.dispatch)

            return {
                ...state,
                isFetchingTab1: true,
                isFetchingTab2: true,
                isFetchingTab3: true,
                isFetchingTab4: true,
                isFetchingTab5: true,
                isFetchingTab6: true,
                isFetchingTab7: true,
                isFetchingTab8: true,
                isFetchingTab9: true,
                isFetchingTab10: true,
            }

        case "FETCH_DATA":
            let temp = state.tab1
            // console.log(temp)
            temp.push(...action.payload.data)
            
            return {
                ...state,
                tab1: [...new Set(temp)],
                isFetchingTab1: false,
                totalRecordsTab1: action.payload.totalRecords,
                batchSizeTab1: action.payload.batchSize,
                columnTab1: action.payload.columns,
            };

        case "FETCH_TAB2_DATA":
            let temp2 = state.tab2
            // console.log(temp)
            temp2.push(...action.payload.data)
            return {
                ...state,
                tab2: [...new Set(temp2)],
                isFetchingTab2: false,
                totalRecordsTab2: action.payload.totalRecords,
                batchSizeTab2: action.payload.batchSize,
                columnTab2: action.payload.columns
            };

        case "FETCH_TAB3_DATA":
            let temp3 = state.tab3
            // console.log(temp)
            temp3.push(...action.payload.data)
            return {
                ...state,
                tab3: [...new Set(temp3)],
                isFetchingTab3: false,
                totalRecordsTab3: action.payload.totalRecords,
                batchSizeTab3: action.payload.batchSize,
                columnTab3: action.payload.columns
            };
        case "FETCH_TAB4_DATA":
            let temp4 = state.tab4
            temp4.push(...action.payload.data)
            return {
                ...state,
                tab4: [...new Set(temp4)],
                isFetchingTab4: false,
                totalRecordsTab4: action.payload.totalRecords,
                batchSizeTab4: action.payload.batchSize,
                columnTab4: action.payload.columns
            };
        case "FETCH_TAB5_DATA":
            let temp5 = state.tab5
            temp5.push(...action.payload.data)
            return {
                ...state,
                tab5: [...new Set(temp5)],
                isFetchingTab5: false,
                totalRecordsTab5: action.payload.totalRecords,
                batchSizeTab5: action.payload.batchSize,
                columnTab5: action.payload.columns
            };
        case "FETCH_TAB6_DATA":
            let temp6 = state.tab6
            temp6.push(...action.payload.data)
            return {
                ...state,
                tab6: [...new Set(temp6)],
                isFetchingTab6: false,
                totalRecordsTab6: action.payload.totalRecords,
                batchSizeTab6: action.payload.batchSize,
                columnTab6: action.payload.columns
            };
        case "FETCH_TAB7_DATA":
            let temp7 = state.tab7
            temp7.push(...action.payload.data)
            return {
                ...state,
                tab7: [...new Set(temp7)],
                isFetchingTab7: false,
                totalRecordsTab7: action.payload.totalRecords,
                batchSizeTab7: action.payload.batchSize,
                columnTab7: action.payload.columns
            };
        case "FETCH_TAB8_DATA":
            let temp8 = state.tab8
            temp8.push(...action.payload.data)
            return {
                ...state,
                tab8: [...new Set(temp8)],
                isFetchingTab8: false,
                totalRecordsTab8: action.payload.totalRecords,
                batchSizeTab8: action.payload.batchSize,
                columnTab8: action.payload.columns
            };
        case "FETCH_TAB9_DATA":
            let temp9 = state.tab9
            temp9.push(...action.payload.data)
            return {
                ...state,
                tab9: [...new Set(temp9)],
                isFetchingTab9: false,
                totalRecordsTab9: action.payload.totalRecords,
                batchSizeTab9: action.payload.batchSize,
                columnTab9: action.payload.columns
            };
        case "FETCH_TAB10_DATA":
            let temp10 = state.tab10
            temp10.push(...action.payload.data)
            return {
                ...state,
                tab10: [...new Set(temp10)],
                isFetchingTab10: false,
                totalRecordsTab10: action.payload.totalRecords,
                batchSizeTab10: action.payload.batchSize,
                columnTab10: action.payload.columns
            };
        case "FETCH_ERROR_TAB1":
            return {
                ...state,
                isErrorTab1: true,
                isFetchingTab1: false,
                isTab1Fetched: false,
                tab1: [],
                columnTab1: [],
                totalRecordsTab1: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB2":
            return {
                ...state,
                isErrorTab2: true,
                isFetchingTab2: false,
                isTab2Fetched: false,
                tab2: [],
                columnTab2: [],
                totalRecordsTab2: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB3":
            return {
                ...state,
                isErrorTab3: true,
                isFetchingTab3: false,
                isTab3Fetched: false,
                tab3: [],
                columnTab3: [],
                totalRecordsTab3: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB4":
            return {
                ...state,
                isErrorTab4: true,
                isFetchingTab4: false,
                isTab4Fetched: false,
                tab4: [],
                columnTab4: [],
                totalRecordsTab4: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB5":
            // console.log("FETCH_ERROR_TAB5");
            return {
                ...state,
                isErrorTab5: true,
                isFetchingTab5: false,
                isTab5Fetched: false,
                tab5: [],
                columnTab5: [],
                totalRecordsTab5: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB6":
            return {
                ...state,
                isErrorTab6: true,
                isFetchingTab6: false,
                isTab6Fetched: false,
                tab6: [],
                columnTab6: [],
                totalRecordsTab6: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB7":
            return {
                ...state,
                isErrorTab7: true,
                isFetchingTab7: false,
                isTab7Fetched: false,
                tab7: [],
                columnTab7: [],
                totalRecordsTab7: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB8":
            return {
                ...state,
                isErrorTab8: true,
                isFetchingTab8: false,
                isTab8Fetched: false,
                tab8: [],
                columnTab8: [],
                totalRecordsTab8: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB9":
            return {
                ...state,
                isErrorTab9: true,
                isFetchingTab9: false,
                isTab9Fetched: false,
                tab9: [],
                columnTab9: [],
                totalRecordsTab9: 0,
                errorMessage: action.error,
            };
            case "FETCH_ERROR_TAB10":
                return {
                    ...state,
                    isErrorTab10: true,
                    isFetchingTab10: false,
                    isTab10Fetched: false,
                    tab10: [],
                    columnTab10: [],
                    totalRecordsTab10: 0,
                    errorMessage: action.error,
                };
        
        case "RESET_TAB1":
            return {
                ...state,
                isFetchingTab1: false,
                isErrorTab1: false,
                isTab1Fetched: false,
                tab1: [],
                totalRecordsTab1: 0,
                batchSizeTab1: 0,
                columnTab1: [],
            };
        case "RESET_TAB2":
            return {
                ...state,
                isFetchingTab2: false,
                isErrorTab2: false,
                isTab2Fetched: false,
                tab2: [],
                totalRecordsTab2: 0,
                batchSizeTab2: 0,
                columnTab2: [],
            };
        case "RESET_TAB3":
            return {
                ...state,
                isFetchingTab3: false,
                isErrorTab3: false,
                isTab3Fetched: false,
                tab3: [],
                totalRecordsTab3: 0,
                batchSizeTab3: 0,
                columnTab3: [],
            };
        case "RESET_TAB4":
            return {
                ...state,
                isFetchingTab4: false,
                isErrorTab4: false,
                isTab4Fetched: false,
                tab4: [],
                totalRecordsTab4: 0,
                batchSizeTab4: 0,
                columnTab4: [],
            };
        case "RESET_TAB5":
            return {
                ...state,
                isFetchingTab5: false,
                isErrorTab5: false,
                isTab5Fetched: false,
                tab5: [],
                totalRecordsTab5: 0,
                batchSizeTab5: 0,
                columnTab5: [],
            };
        case "RESET_TAB6":
            return {
                ...state,
                isFetchingTab6: false,
                isErrorTab6: false,
                isTab6Fetched: false,
                tab6: [],
                totalRecordsTab6: 0,
                batchSizeTab6: 0,
                columnTab6: [],
            };
        case "RESET_TAB7":
            return {
                ...state,
                isFetchingTab7: false,
                isErrorTab7: false,
                isTab7Fetched: false,
                tab7: [],
                totalRecordsTab7: 0,
                batchSizeTab7: 0,
                columnTab7: [],
            };
        case "RESET_TAB8":
            return {
                ...state,
                isFetchingTab8: false,
                isErrorTab8: false,
                isTab8Fetched: false,
                tab8: [],
                totalRecordsTab8: 0,
                batchSizeTab8: 0,
                columnTab8: [],
            };
        case "RESET_TAB9":
            return {
                ...state,
                isFetchingTab9: false,
                isErrorTab9: false,
                isTab9Fetched: false,
                tab9: [],
                totalRecordsTab9: 0,
                batchSizeTab9: 0,
                columnTab9: [],
            };
        case "RESET_TAB10":
            return {
                ...state,
                isFetchingTab10: false,
                isErrorTab10: false,
                isTab10Fetched: false,
                tab10: [],
                totalRecordsTab10: 0,
                batchSizeTab10: 0,
                columnTab10: [],
            };
        case "TAB1_STATUS":
            return {
                ...state,
                isTab1Fetched: action.status
            };
        case "TAB2_STATUS":
            return {
                ...state,
                isTab2Fetched: action.status
            };
        case "TAB3_STATUS":
            return {
                ...state,
                isTab3Fetched: action.status
            };
        case "TAB4_STATUS":
            return {
                ...state,
                isTab4Fetched: action.status
            };
        case "TAB5_STATUS":
            return {
                ...state,
                isTab5Fetched: action.status
            };
        case "TAB6_STATUS":
            return {
                ...state,
                isTab6Fetched: action.status
            };
        case "TAB7_STATUS":
            return {
                ...state,
                isTab7Fetched: action.status
            };
        case "TAB8_STATUS":
            return {
                ...state,
                isTab8Fetched: action.status
            };
        case "TAB9_STATUS":
            return {
                ...state,
                isTab9Fetched: action.status
            };
        case "TAB10_STATUS":
            return {
                ...state,
                isTab10Fetched: action.status
            };

        default:
            return;
    }
};

export const Scenario4n9Context = createContext(INITIAL_STATE);

export const Scenario4n9Provider = (props) => {
    // console.log("SC4n9 Context called !!!")
    const { filterState } = useContext(FilterContext);
    // console.log("Transactions filter state:::", filterState);

    const [sc4n9State, sc4n9Dispatch] = useReducer(reducer, INITIAL_STATE);

    return (
        <Scenario4n9Context.Provider value={{ sc4n9State, sc4n9Dispatch }}>
            {props.children}
        </Scenario4n9Context.Provider>
    );
};

const fetchTab1APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab1 Api Data :::")
    // console.log("sc4n9  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB1"});
        dispatch({ type: "FETCH_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_DATA", payload: result});
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                // console.log("1. TAB1_STATUS", offsetCounter)
                dispatch({ type: "TAB1_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB1_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB1", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 1 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 4 and 9 Tab 1 :: " + error.response.data.message, 20);
    }
}

const fetchTab2APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab2 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB2"});
        dispatch({ type: "FETCH_TAB2_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB2_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB2_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB2_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB2", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 2 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 4 and 9 Tab 2 :: " + error.response.data.message, 20);
    }
}

const fetchTab3APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab3 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/3?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB3"});
        dispatch({ type: "FETCH_TAB3_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/3?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB3_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB3_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB3_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB3", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 3 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 4 and 9 Tab 3 :: " + error.response.data.message, 20);
    }
}

const fetchTab4APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab4 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/4?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB4"});
        dispatch({ type: "FETCH_TAB4_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/4?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB4_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB4_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB4_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB4", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 4 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 4 and 9 Tab 4 :: " + error.response.data.message, 20);
    }
}

const fetchTab5APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab5 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/5?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB5"});
        dispatch({ type: "FETCH_TAB5_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/5?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB5_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB5_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB5_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error.response.data.message);
        dispatch({ type: "FETCH_ERROR_TAB5", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 5 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 4 and 9 Tab 5 :: " + error.response.data.message, 20);
    }
}

const fetchTab6APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab6 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/6?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB6"});
        dispatch({ type: "FETCH_TAB6_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/6?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB6_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB6_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB6_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB6", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 6 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 4 and 9 Tab 6 :: " + error.response.data.message, 20);
    }
}

const fetchTab7APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab7 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/7?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB7"});
        dispatch({ type: "FETCH_TAB7_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/7?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB7_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB7_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB7_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB7", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 7 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 1 and 3 Tab 7 :: " + error.response.data.message, 20);
    }
}

const fetchTab8APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab8 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/8?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB8"});
        dispatch({ type: "FETCH_TAB8_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/8?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB8_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB8_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB8_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB8", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 8 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 1 and 3 Tab 7 :: " + error.response.data.message, 20);
    }
}

const fetchTab9APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab9 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/9?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB9"});
        dispatch({ type: "FETCH_TAB9_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/9?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB9_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB9_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB9_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB9", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 8 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 1 and 3 Tab 7 :: " + error.response.data.message, 20);
    }
}

const fetchTab10APiData = async (filterState, dispatch) => {
    // console.log("sc4n9 Fetch Tab10 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/10?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc4n9  data:::", result);
        dispatch({type: "RESET_TAB10"});
        dispatch({ type: "FETCH_TAB10_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc4n9/10?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB10_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB10_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB10_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error.response);
        dispatch({ type: "FETCH_ERROR_TAB10", error: error.response.data.message });
        // message.error("Sc 4 and 9 Tab 8 : Somethime went wrong, Please try again !!!", 20);
        // message.error("Sc 1 and 3 Tab 7 :: " + error.response.data.message, 20);
    }
}