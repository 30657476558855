import { Row, Col } from "react-bootstrap";
import React, { useState, useContext } from "react";
import "antd/dist/antd.css";

import axios from "axios";
import { message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import { Scenario10Context } from '../../../../context/Scenario10Context'

import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
  Space,
  Spin,
  Tooltip,
  Card,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";



export const SC10Tab1 = (props) => {
  const { sc10State } = useContext(Scenario10Context);
  let transformedData, data, columns, totalRecords;
  const antIcon = <LoadingOutlined style={{ fontSize: 20, fontWeight: "bold" }} spin />
  // console.log("SC 10 - Tab 1 ")
  // console.log("SC2Tab1 isFetching ::: ", state.isFetching)
  // console.log("Submit Button :::", state.isSubmitClicked)

  // if (state.isFetching == false) {
  // console.log("Type of state ::", typeof (state))
  // console.log("SC 2 - Tab 1 Data::", sc10State);
  // console.log("SC2 Tab1 Total Records ::", sc10State.totalRecordsTab1)
  // console.log("SC2 Tab1 Batch Size ::", sc10State.batchSizeTab1)
  // let transformedData, data, columns;
  // console.log("data####", sc10State.tab1);
  if (sc10State.tab1) {
    // reportDate = state.tab2[0]["Report Date"];
    // setReportDate(reportDate);
    data = sc10State.tab1;
    columns = sc10State.columnTab1;
    totalRecords = sc10State.totalRecordsTab1;
    // console.log("summary data::", data);
    // console.log("summary columns::", columns);

    transformedData = data.map((record) => {
      record["key"] = record["index"];
      return record;
    });
  }
  // }



  let [searchState, setSearchState] = useState("");
  searchState = {
    searchText: "",
    searchedColumn: "",
  };

  let searchInput;
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchState.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchState({ searchText: "" });
  }

  // console.log("transform Data", transformedData);
  // console.log("summary Columns:", columns);
  columns = columns.filter(item => item !== "index")
  let tbColumnsList;
  if (columns && columns.length > 0) {
    tbColumnsList = columns.map((tbcolumn) => {
      if (["DB Amount", "TB in local Curr."].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "right",
          ...getColumnSearchProps(tbcolumn),
          ellipsis: {
            showTitle: false,
          },
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>;
            return {
              props: {
                style: { color: text.includes("(") ? "red" : "" },
              },
              children: <div>{text}</div>,
            };
          },
        };
      }
      if (["Rate", "Foreign", "Local", "Comm-ission", "Fees", "TotalDue"].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "right",
          ellipsis: {
            showTitle: false,
          },
          ...getColumnSearchProps(tbcolumn),
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text},
            </Tooltip>;
            return {
              props: {
                style: { color: parseInt(text) < 0 ? "red" : "" },
              },
              children: (
                <div>
                  {
                    <NumberFormat
                      value={text}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  }
                </div>
              ),
            };
          },
        };
      }
      return {
        title: tbcolumn,
        dataIndex: tbcolumn,
        align: "left",
        ...getColumnSearchProps(tbcolumn),
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      };
    });
  }
  // console.log("tb column list::", tbColumnsList);
  // console.log("summary column list::", tbColumnsList);
  const [form] = Form.useForm();

  return (
    <>
      <Card>
        <Row style={{margin: "-15px 0px 10px 0px"}}>  
          <Col>
            <div style={{ float: "left" }}>
              <Space>
                <Col align="left">
                  {sc10State.tab1 && totalRecords !== sc10State.tab1.length ? (
                    <span style={{ marginTop: "0px" }}>
                      <b>
                        <i>
                          Showing {sc10State.tab1.length} of {totalRecords}  <Spin indicator={antIcon} />
                        </i>
                      </b>{" "}
                    </span>
                  ) : (
                    <span style={{ marginTop: "0px" }}>
                      <b>{sc10State.tab1 ? (<i>Showing Total {sc10State.tab1.length} records</i>): ("")}
                        
                      </b>
                    </span>
                  )}
                </Col>
                
              </Space>
            </div>
          </Col>
        </Row>
        <Row>
        <div>
          {sc10State.isFetchingTab1 ? (
            <Spin tip="Loading...." />
          ) : (
            <Form form={form} component={false}>
              <Table
                components={{}}
                bordered
                dataSource={transformedData}
                columns={tbColumnsList}
                size="small"
                scroll={{ x: 1500, y: 400 }}
              />
            </Form>
          )}
        </div>
        </Row>
      </Card>
    </>
  );
};

export const SC10Tab2 = (props) => {
  const { sc10State } = useContext(Scenario10Context);
  // console.log("Type of state ::", typeof (sc10State))
  // console.log("SC 10 - Tab 2 Data::", sc10State);

  let transformedData, data, columns, totalRecords;
  const antIcon = <LoadingOutlined style={{ fontSize: 20, fontWeight: "bold" }} spin />
  // console.log("data####", state.tab2);
  if (sc10State.tab2) {
    // reportDate = state.tab2[0]["Report Date"];
    // setReportDate(reportDate);
    data = sc10State.tab2;
    columns = sc10State.columnTab2;
    totalRecords = sc10State.totalRecordsTab2;
    // console.log("summary data::", data);
    // console.log("summary columns::", columns);

    transformedData = data.map((record) => {
      record["key"] = record["index"];
      return record;
    });
  }

  let [searchState, setSearchState] = useState("");
  searchState = {
    searchText: "",
    searchedColumn: "",
  };

  let searchInput;
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchState.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchState({ searchText: "" });
  }

  // console.log("transform Data", transformedData);
  // console.log("summary Columns:", columns);
  columns = columns.filter(item => item !== "index")
  let tbColumnsList;
  if (columns && columns.length > 0) {
    tbColumnsList = columns.map((tbcolumn) => {
      if (["Split Transaction"].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "right",
          ...getColumnSearchProps(tbcolumn),
          ellipsis: {
            showTitle: false,
          },
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>;
            return {
              props: {
                style: { background: text.includes("YES") ? "yellow" : "" },
              },
              children: <div>{text}</div>,
            };
          },
        };
      }
      if (["Rate", "Foreign", "Local", "TotalDue", "Transaction_Sum"].includes(tbcolumn)) {
        return {
          title: tbcolumn,
          dataIndex: tbcolumn,
          align: "right",
          ellipsis: {
            showTitle: false,
          },
          ...getColumnSearchProps(tbcolumn),
          render(text, record) {
            <Tooltip placement="topLeft" title={text}>
              {text},
            </Tooltip>;
            return {
              props: {
                style: { color: parseInt(text) < 0 ? "red" : "" },
              },
              children: (
                <div>
                  {
                    <NumberFormat
                      value={text}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  }
                </div>
              ),
            };
          },
        };
      }
      return {
        title: tbcolumn,
        dataIndex: tbcolumn,
        align: "left",
        ...getColumnSearchProps(tbcolumn),
        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      };
    });
  }
  // console.log("tb column list::", tbColumnsList);
  // console.log("summary column list::", tbColumnsList);
  const [form] = Form.useForm();

  return (
    <>
      <Card>
      <Row style={{margin: "-15px 0px 10px 0px"}}>  
          <Col>
            <div style={{ float: "left" }}>
              <Space>
                <Col align="left">
                  {sc10State.tab2 && totalRecords !== sc10State.tab2.length ? (
                    <span style={{ marginTop: "0px" }}>
                      <b>
                        <i>
                          Showing {sc10State.tab2.length} of {totalRecords}  <Spin indicator={antIcon} />
                        </i>
                      </b>{" "}
                    </span>
                  ) : (
                    <span style={{ marginTop: "0px" }}>
                      <b>
                        <i>Showing Total {sc10State.tab2.length} records</i>
                      </b>
                    </span>
                  )}
                </Col>
                
              </Space>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            {sc10State.isFetchingTab2 ? (
              <Spin tip="Loading...." />
            ) : (
              <Form form={form} component={false}>
                <Table
                  components={{}}
                  bordered
                  dataSource={transformedData}
                  columns={tbColumnsList}
                  size="small"
                  scroll={{ x: 1900 }}
                
                />
              </Form>
            )}
          </div>
        </Row>
      </Card>
    </>
  );
};
