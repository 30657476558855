import React, {useContext} from "react";
import { AiOutlineLogout } from "react-icons/ai"
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './NavbarElements';
import "./topbar.css";
// import { Navbar, Nav, NavDropdown} from "react-bootstrap";
// import {NavLink} from "react-router-dom"

import { FilterContext } from "../../../context/FilterContext"

import { Row, Col, Button, message, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import logo from "./travelex-logo-old.svg";
import middlewareLogo from "./middleware-logo.png";
import Cookies from "js-cookie";
import { logoutUser } from "../../../api/logout";


// class Topbar extends React.Component {
export const Topbar = () => {

  const logout = async (e) => {
      try {

          let body = JSON.stringify({
              code: Cookies.get('code')
          })
          // console.log("Push logout :::", this.props);
          console.log("Logout event :::", e);
          console.log("Compliance url :::", `${process.env.REACT_APP_COMPLIANCE_URL}`);
          console.log("Cookies code ::: ", body);
          const res = await logoutUser(body)

          if (res && res.message === "Token invalidated") {
              //clear cookies and push to login
              Cookies.remove('expiresIn');
              Cookies.remove('code');
              Cookies.remove('code_challenge');
              Cookies.remove('code_verifier');
              console.log("code :::", Cookies.get('code'));
              console.log("Cookies code After ::: ", body);
              // this.props.history.push("/");
              window.location.href = `/`;
          } else {
              message.error(res.message || 'Error Occured');
          }
      } catch (err) {
          console.log('Error Occured', err);
      }

  }
  // render() {
    return (
      <Row
        style={{
          backgroundColor: "#e3f1fe",
          borderBottom: "1px solid rgba(28,34,67,.2901960784313726)",
        }}
      >
        <Col span={2}>
          <img
            alt=""
            src={logo}
            width="120"
            height="50"
            className="d-inline-block align-top"
          />
        </Col>
        <Col span={7}>
          <h4 style={{ paddingLeft: "20px", paddingTop: "10px", fontSize:"20px" }}>
            Transaction Review Reporting
          </h4>
        </Col>
        <Col span={4}>
        </Col>
        <Col span={10}>
          <Nav className="justify-content-end">
            <NavMenu>
              <NavLink to="/" activeStyle={{fontWeight: "bold"}}>HOME</NavLink>
              {/* <NavLink to="/sc1_3" activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >SC 1 and 3</NavLink>
              <NavLink to='/sc2' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >SC 2</NavLink>
              <NavLink to='/sc4_9' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >SC 4 and 9</NavLink>
              <NavLink to='/sc5' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >SC 5</NavLink>
              <NavLink to='/sc6_7' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >SC 6 and 7</NavLink>
              <NavLink to='/sc8' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >SC 8</NavLink> */}
              {/* {props.userIdentity ? (
                <NavLink to='/sc10' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >Split Trans</NavLink>
              ): (
                ""
              )} */}
              <NavLink to='/sc10' activeStyle={{fontWeight: "bold", borderBottom: "1px solid #2196f3"}} >Split Trans</NavLink>
            </NavMenu>
          </Nav>
        </Col>

        <Col span={1}>
          <Tooltip placement="top" title={"Logout"}><LogoutOutlined style={{float:"right", paddingTop:"10px", height:"30px", width:"35px", fontSize: '28px'}} onClick={() => logout()} alt="Logout" /></Tooltip>
        </Col>
      </Row>
    );
  // }
}

export default Topbar;
