import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";

import "antd/dist/antd.css";
import { Tabs, Row, Col, Select } from "antd";
import { DatePicker, Space } from "antd";
import { message } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import dal from "../../../dal/api-call";

import { } from "react-bootstrap";
import { FilterContext, } from "../../../context/FilterContext"
import { Scenario2Context } from "../../../context/Scenario2Context"
import { Scenario1n3Context } from "../../../context/Scenario1n3Context"
import { Scenario6n7Context } from "../../../context/Scenario6n7Context"
import { Scenario8Context } from "../../../context/Scenario8Context";
import { Scenario4n9Context } from "../../../context/Scenario4n9Context"
import { Scenario5Context } from "../../../context/Scenario5Context"
import { Scenario10Context } from "../../../context/Scenario10Context"
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

const { Option } = Select;

let countryList = [];

export const FilterComponent = () => {
    
    // console.log("User Mapping :: ", user_mapping, " --- ",tempUID);
    let code = Cookies.get('code');

    const [countries, setCountries] = useState([]);
    const [selectedGeo, setSelectedGeo] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    // const [disable, setDisable] = useState(false);
    const [geoList, setGeoList] = useState([]);
    const [userIdentity , setUserIdentity] = useState(null);

    const { filterState, filterDispatch } = useContext(FilterContext);
    const { dispatch } = useContext(Scenario2Context);

    const { sc1n3Dispatch } = useContext(Scenario1n3Context);

    const { sc6n7Dispatch } = useContext(Scenario6n7Context);

    const { sc8Dispatch } = useContext(Scenario8Context);

    const {sc4n9Dispatch } = useContext(Scenario4n9Context);

    const { sc5Dispatch } = useContext(Scenario5Context);

    const {sc10Dispatch } = useContext(Scenario10Context);

    let url = window.location.pathname;
    
    useEffect(() => {
        // console.log("Filter component useEffect...");
        if(code){
            if(!userIdentity){
                // console.log("Inside if condition.")
                dal.request(`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}v1/user/identity`)
                // dal.request(`https://compliance.dev.api.pe.tvx-test.cloud/ui/v1/user/identity`)
                    .then((response) => {
                        // console.log("Auth response :: ", response)
    
                        // userIdentity = response.data;
                        if(response.status===200){
                            setUserIdentity(JSON.parse(JSON.stringify(response.data)));
                        }else{
                            onFailure("Error Message","You are not authorized to perform this action. Kindly request the required privileges from your admin!")
                        }
                    })
                    .catch((error) => console.log(error));
            }
            // console.log("Test 2 ",geoList.length)
            if(userIdentity && geoList.length === 0){
                if(userIdentity.geo && Array.isArray(userIdentity.geo) && userIdentity.geo.length !==0){
                    // console.log("test 1")
                    countryList = Object.values(userIdentity.geo).map(key => ({
                        name:key
                    }))
                    setGeoList(countryList);
                }else{
                    onFailure("Error Message","You are not authorized to perform this action. Kindly request the required privileges from your admin!!")
                }
            }
        }else{
            sweetAlert();
        }
        
    }, [userIdentity, geoList])

    const countriesList = {
        Africa: ["All", "Nigeria"],
        ANZ: ["All", "Australia", "New Zealand"],
        Asia: ["All", "China", "Hong Kong", "Japan", "Malaysia", "Singapore" ],
        Europe: ["All", "Germany", "Netherlands", "Switzerland"],
        MET: ["All", "Oman", "Turkey (5210)", "Turkey (5211)", "UAE"],
        UK: ["All","Asda", "Tesco", "TFCS"]
    };

    function handleGeoSelect(e) {
        // console.log(e)
        // console.log("Selected country", e);
        const geoSel = e;
        const countrySel = geoSel !== "" ? countriesList[geoSel] : "";
        setSelectedGeo(geoSel);
        setCountries(countrySel);
        setSelectedCountry("");
        filterDispatch({ type: "GEO_CHANGE", value: geoSel })
        filterDispatch({ type: "COUNTRY_CHANGE", value: "" })
    }

    function handleCountrySelect(e) {

        // console.log("Selected city", e);
        const countrySel = e;
        setSelectedCountry(countrySel);

        filterDispatch({ type: "COUNTRY_CHANGE", value: countrySel })
    }

    function handleSubmit(e, filterState) {
        e.preventDefault();
        try {
            if(e.target.baseURI.split("/")[3] === "sc10") {
                // console.log("Scenario 10 dispatch")
                if(filterState.geo === "" || filterState.country === "" || filterState.date === ""){
                    throw new Error("Please select all fields before sbmitting !!!")
                } else {
                    sc10Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc10Dispatch, filterState: filterState })
                }
            }else {
                if(filterState.geo === "" || filterState.country === "" || filterState.year === "" || filterState.month === ""){
                
                    throw new Error("Please select all fields before sbmitting !!!")
                } else {
                    // console.log("Filter Component Filter State :::: ", filterState);
                    filterDispatch({type: "HANDLE_SUBMIT_STATE"});
                    // console.log(e.target.baseURI);
                    // console.log('You clicked submit.');
                    // console.log(e.target.baseURI.split("/")[3]);
    
                    if(e.target.baseURI.split("/")[3] === "sc1_3"){
                        // console.log("Scenario 1 and 3 dispatch")
                        if(filterState.periodCat === "" || filterState.period === ""){
                            throw new Error("Please select all fields before sbmitting !!!")
                        } else {
                            sc1n3Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc1n3Dispatch, filterState: filterState })
                        }
                        
                    } else if(e.target.baseURI.split("/")[3] === "sc2"){
                        // console.log("Scenario 2 dispatch")
                        dispatch({ type: 'HANDLE_SUBMIT', dispatch: dispatch, filterState: filterState })
                    } else if(e.target.baseURI.split("/")[3] === "sc6_7") {
                        // console.log("Scenario 6 and 7 dispatch")
                        sc6n7Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc6n7Dispatch, filterState: filterState })
                    } else if(e.target.baseURI.split("/")[3] === "sc8") {
                        // console.log("Scenario 8 dispatch")
                        sc8Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc8Dispatch, filterState: filterState })
                        // if(filterState.periodCat === ""){
                        //     throw new Error("Please select all fields before sbmitting !!!")
                        // } else {
                        //     sc8Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc8Dispatch, filterState: filterState })
                        // }
                        
                    } else if(e.target.baseURI.split("/")[3] === "sc4_9") {
                        // console.log("Scenario 4 n 9 dispatch")
                        sc4n9Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc4n9Dispatch, filterState: filterState })
                    } else if(e.target.baseURI.split("/")[3] === "sc5") {
                        // console.log("Scenario 5 dispatch")
                        if(filterState.periodCat === "" || filterState.period === ""){
                            throw new Error("Please select all fields before sbmitting !!!")
                        } else {
                            sc5Dispatch({ type: 'HANDLE_SUBMIT', dispatch: sc5Dispatch, filterState: filterState })
                        }
                        
                    } 
                }
            }
            
            
        }catch(error){
            message.error(error.message);
        }
        
    }

    function sweetAlert() {
        Swal.fire({
            title: 'Session has expired',
            text: 'Redirecting to the login page',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = '/';
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // window.location.href = '/login';
            }
        });
    }
    function onFailure(title,message){
        Swal.fire({
            title: title,
            text: message,
            icon: "error",
            showCloseButton: true,
            confirmButtonText: "OK"
        }).then(data => {
            console.log(data);
            if (data.isConfirmed === true){
                window.location.href = '/';
            }
        })
    }

    // console.log("FilterComponent userIdentity:: ", userIdentity, " ---- ", geoList);
    // if(userIdentity){
    //     console.log("Geo list ", userIdentity.geo, " --- ", geoList);
    // }
    return (
        <Row>
            <Col span={4}>
                
                <Select
                    name="GeoLocation"
                    style={{ width: 200, padding: "0px 4px 0px 4px", marginTop: "60px" }}
                    placeholder="Select Geo"
                    optionFilterProp="children"
                    onChange={e => handleGeoSelect(e)}
                    value={selectedGeo}
                    
                >
                    <option value="" disabled>Select the Geo</option>
                    {geoList.map((geo, key) => (
                        <option key={key} value={geo.name}>
                            {geo.name}
                        </option>
                    ))}
                    {/* {countryList.map((geo, key) => (
                        <option key={key} value={geo.name}>
                            {geo.name}
                        </option>
                    ))} */}
                    
                </Select>
                <Select
                    showSearch
                    name="Countries"
                    style={{ width: 200, padding: "4px 4px 0px 4px" }}
                    placeholder="Select the Country"
                    optionFilterProp="children"
                    onChange={e => handleCountrySelect(e)}
                    value={selectedCountry}
                    // filterOption={(input, option) =>
                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                >
                    <option value="" disabled>Select the Country</option>
                    {countries.map((country, key) => (
                        <Option key={key} value={country}>
                            {country}
                        </Option>
                    ))}
                    
                </Select>
                {url === "/sc10" ? (
                    <Space direction="vertical" style={{ width: 200, padding: "0px 4px 4px 4px", marginTop: "4px"  }}>
                    <DatePicker
                    //   defaultValue={filterState.date}
                      style={{width: 192}}
                      onChange={(e) => filterDispatch({ type: "DATE_CHANGE", value: e })}
                    />
                  </Space>
                ) : ("")}

                {url === "/sc1_3" || url === "/sc2" || url === "/sc4_9" || url === "/sc5" || url === "/sc6_7" || url === "/sc8" ? (
                    <Select
                        style={{ width: 200, padding: "4px 4px 0px 4px" }}
                        placeholder="Select Year"
                        optionFilterProp="children"
                        onChange={(e) => filterDispatch({ type: "YEAR_CHANGE", value: e })}
                    >
                        <Option value="2021">2021</Option>
                        <Option value="2020">2020</Option>
                    </Select>
                ) : ("")}
                
                {url === "/sc8" ? (
                    <>
                        <Select
                            style={{ width: 200, padding: "4px 4px 6px 4px" }}
                            placeholder="Select Month"
                            optionFilterProp="children"
                            onChange={(e) => filterDispatch({ type: "MONTH_CHANGE", value: e })}
                        >
                            <Option value="March">Q1 (Jan-Feb-Mar)</Option>
                            <Option value="June">Q2 (Apr-May-Jun)</Option>
                            <Option value="September">Q3 (Jul-Aug-Sept)</Option>
                            <Option value="December">Q4 (Oct-Nov-Dec)</Option>
                        </Select>
                    </>
                ): (
                    
                    <>
                    {url === "/sc1_3" || url === "/sc2" || url === "/sc4_9" || url === "/sc5" || url === "/sc6_7" ? (
                        <Select
                            style={{ width: 200, padding: "4px 4px 6px 4px" }}
                            placeholder="Select Month"
                            optionFilterProp="children"
                            onChange={(e) => filterDispatch({ type: "MONTH_CHANGE", value: e })}
                        >
                            <Option value="January">January</Option>
                            <Option value="February">Feburary</Option>
                            <Option value="March">March</Option>
                            <Option value="April">April</Option>
                            <Option value="May">May</Option>
                            <Option value="June">June</Option>
                            <Option value="July">July</Option>
                            <Option value="August">August</Option>
                            <Option value="September">September</Option>
                            <Option value="October">October</Option>
                            <Option value="November">November</Option>
                            <Option value="December">December</Option>
                        </Select>
                    ) : ("")}
                        
                    </>
                )}
                
                
                {url === "/sc1_3" || url === "/sc5" ? ( 
                    <>
                        <Select
                        name="periodCategory"
                        style={{ width: 200, padding: "0px 4px 0px 4px" }}
                        optionFilterProp="children"
                        placeholder="Select the Period Category"
                        onChange={(e)=> filterDispatch({ type: "PERIOD_CAT_CHANGE", value: e })}
                        >
                            <option value="" disabled>Select the Period Category</option>
                            
                            <Option value="Weekly">Weekly</Option>
                        </Select>
                        <Select
                            name="period"
                            style={{ width: 200, padding: "4px 4px 4px 4px" }}
                            optionFilterProp="children"
                            placeholder="Select the Period"
                            onChange={(e)=> filterDispatch({ type: "PERIOD_CHANGE", value: e })}
                        >
                        <option value="" disabled>Select the Period</option>
                       
                            <Option value="Week1">Week 1</Option>
                            <Option value="Week2">Week 2</Option>
                            <Option value="Week3">Week 3</Option>
                            <Option value="Week4">Week 4</Option>
                            <Option value="Week5">Week 5</Option>
                        </Select>

                    </>
                ): ("")}
                {/* {url === "/sc8" ? (
                    <>
                        <Select
                        style={{ width: 200, padding: "0px 4px 4px 4px" }}
                        placeholder="Select Period Category"
                        optionFilterProp="children"
                        onChange={(e)=> filterDispatch({ type: "PERIOD_CAT_CHANGE", value: e })}
                        >
                            <option value="" disabled>Select the Period Category</option>
                            <Option value="Quarterly">Quarterly</Option>
                        </Select>
                    </>
                ) : ("")} */}
                {/* <Button variant="outline-primary" size="sm" style={{ width: 198 }} onClick={(e) => handleSubmit(e, filterState)}>Submit</Button> */}
                {/* <Button disabled={disable} variant="outline-primary" size="sm" style={{ width: 198 }} onClick={(e) => {handleSubmit(e, filterState);    setDisable(true);}}>Submit</Button> */}
                {filterState.isSubmitButtonDisable ? (
                    <Button disabled={true} variant="primary" size="sm" style={{ width: 198 }} onClick={(e) => handleSubmit(e, filterState)}>Run Report</Button>
                ): (
                    <Button variant="primary" size="sm" style={{ width: 198 }} onClick={(e) => handleSubmit(e, filterState)}>Run Report</Button>
                )}
                
            </Col>
        </Row>
    )
    // }
}

// export default filterState;
// export default FilterContext;
// export default Scenario1and3;

