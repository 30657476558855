import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Login from './views/login';
import Setup from './components/SetupCode';
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";  // Import your PrivateRoute component

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        {/* Protected route */}
        <PrivateRoute path="/dashboard">
          <App />
        </PrivateRoute>

        {/* Unprotected routes */}
        <Route path="/setup/:slug">
          <Setup />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
