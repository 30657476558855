import React, { createContext, useReducer, useEffect, useContext } from "react";
import axios from "axios";

// import { Scenario2Context } from "../../../../context/scenario2/Scenario2Context"

import { FilterContext } from "./FilterContext"
import { message } from "antd";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
    tab1: [],
    tab2: [],
    tab3: [],
    isFetching: false,
    isFetchingTab1: false,
    isErrorTab1: false,
    isFetchingTab2: false,
    isErrorTab2: false,
    isFetchingTab3: false,
    isErrorTab3: false,
    totalRecords: 0,
    batchSize: 0,
    columns: [],
    updatedRecords: [],
    reportDate: "",
    isSubmitClicked: false,
    totalRecordsTab1: 0,
    totalRecordsTab2: 0,
    totalRecordsTab3: 0,
    batchSizeTab1: 0,
    batchSizeTab2: 0,
    batchSizeTab3: 0,
    offsetTab1: 0,
    limitTab1: 50,
    columnTab1: [],
    columnTab2: [],
    columnTab3: [],
    isTab1Fetched: false,
    isTab2Fetched: false,
    isTab3Fetched: false,
    errorMessage: "",
};

let reducer = (state, action) => {
    // console.log(action)
    switch (action.type) {
        case "HANDLE_SUBMIT":
            fetchTab1APiData(action.filterState, action.dispatch)
                // .then(response => {action.dispatch({ type: 'FETCH_DATA', payload: response.data })                })

            fetchTab2APiData(action.filterState, action.dispatch)
                // .then(response => action.dispatch({ type: 'FETCH_TAB2_DATA', payload: response.data }))

            fetchTab3APiData(action.filterState, action.dispatch)
                // .then(response => action.dispatch({ type: 'FETCH_TAB3_DATA', payload: response.data }))
            return {
                ...state,
                isFetchingTab1: true,
                isFetchingTab2: true,
                isFetchingTab3: true
            }

        case "FETCH_DATA":
            let temp = state.tab1
            // console.log(temp)
            temp.push(...action.payload.data)
            
            return {
                ...state,
                tab1: [...new Set(temp)],
                isFetchingTab1: false,
                totalRecordsTab1: action.payload.totalRecords,
                batchSizeTab1: action.payload.batchSize,
                columnTab1: action.payload.columns,
            };

        case "FETCH_TAB2_DATA":
            // console.log("SC2 Tab2 :::", action.payload.data)
            let temp2 = state.tab2
            // console.log(temp)
            temp2.push(...action.payload.data)
            return {
                ...state,
                tab2: [...new Set(temp2)],
                isFetchingTab2: false,
                totalRecordsTab2: action.payload.totalRecords,
                batchSizeTab2: action.payload.batchSize,
                columnTab2: action.payload.columns
            };

        case "FETCH_TAB3_DATA":
            let temp3 = state.tab3
            // console.log(temp)
            temp3.push(...action.payload.data)
            return {
                ...state,
                tab3: [...new Set(temp3)],
                isFetchingTab3: false,
                totalRecordsTab3: action.payload.totalRecords,
                batchSizeTab3: action.payload.batchSize,
                columnTab3: action.payload.columns
            };

        case "FETCHING":
            return {
                ...state,
                isFetchingTab1: true,
                isFetchingTab2: true,
                isFetchingTab3: true,
                isSubmitClicked: true,
            };

        case "FETCH_ERROR_TAB1":
            return {
                ...state,
                isErrorTab1: true,
                isFetchingTab1: false,
                isTab1Fetched: false,
                tab1: [],
                columnTab1: [],
                totalRecordsTab1: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB2":
            return {
                ...state,
                isErrorTab2: true,
                isFetchingTab2: false,
                isTab2Fetched: false,
                tab2: [],
                columnTab2: [],
                totalRecordsTab2: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB3":
            return {
                ...state,
                isErrorTab3: true,
                isFetchingTab3: false,
                isTab3Fetched: false,
                tab3: [],
                columnTab3: [],
                totalRecordsTab3: 0,
                errorMessage: action.error,
            };
        
        case "RESET_TAB1":
            return {
                ...state,
                isErrorTab1: false,
                isFetchingTab1: false,
                isTab1Fetched: false,
                tab1: [],
                totalRecordsTab1: 0,
                batchSizeTab1: 0,
                columnTab1: [],
            };
        case "RESET_TAB2":
            return {
                ...state,
                isErrorTab2: false,
                isFetchingTab2: false,
                isTab2Fetched: false,
                tab2: [],
                totalRecordsTab2: 0,
                batchSizeTab2: 0,
                columnTab2: [],
            };
        case "RESET_TAB3":
            return {
                ...state,
                isErrorTab3: false,
                isFetchingTab3: false,
                isTab3Fetched: false,
                tab3: [],
                totalRecordsTab3: 0,
                batchSizeTab3: 0,
                columnTab3: [],
            };

        case "TAB1_STATUS":
            return {
                ...state,
                isTab1Fetched: action.status
            }
        case "TAB2_STATUS":
            return {
                ...state,
                isTab2Fetched: action.status
            }
        case "TAB3_STATUS":
            return {
                ...state,
                isTab3Fetched: action.status
            }
        default:
            return;
    }
};

export const Scenario2Context = createContext(INITIAL_STATE);

export const Scenario2Provider = (props) => {
    // console.log("Sc2 Context called !!!")
    const { filterState } = useContext(FilterContext);

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    return (
        <Scenario2Context.Provider value={{ state, dispatch }}>
            {props.children}
        </Scenario2Context.Provider>
    );
};

const fetchTab1APiData = async (filterState, dispatch) => {
    // console.log("Fetch Tab1 Api Data :::")
    // console.log("Filter State:::", filterState)
    offset = 0;
    // console.log("off set::", offset);
    // console.log("limit::", limit);
    // console.log("URL ::"+ process.env.REACT_APP_COMPLIANCE_API_BASE_PATH)
    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc2/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("data:::", result);
        dispatch({type: "RESET_TAB1"});
        dispatch({ type: "FETCH_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc2/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB1_STATUS", status: true});
            }
        }else {
            dispatch({ type: "TAB1_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB1", error: error.response.data.message });
        // message.error("Sc 2 Tab 1 : Somethime went wrong, Please try again !!!", 20);
        // dispatch({ type: "FETCH_ERROR" });
        // message.error(error.response.data.message);
    }
}

const fetchTab2APiData = async (filterState, dispatch) => {
    // console.log("Fetch Tab2 Api Data :::")
    offset = 0;
    // console.log("off set::", offset);
    // console.log("limit::", limit);
    // console.log("isFetching ::: ", filterState.isFetching)
    // console.log("isFetching ::: ", filterState.isFetching)
    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc2/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("data:::", result);
        dispatch({type: "RESET_TAB2"});
        dispatch({ type: "FETCH_TAB2_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc2/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB2_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB2_STATUS", status: true});
            }
        }else {
            dispatch({ type: "TAB2_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB2", error: error.response.data.message });
        // message.error("Sc 2 Tab 2 : Somethime went wrong, Please try again !!!", 20);
        // message.error(error.response.data.message);
    }

}

const fetchTab3APiData = async (filterState, dispatch) => {
    // console.log("Fetch Tab3 Api Data :::")
    offset = 0;
    // console.log("off set::", offset);
    // console.log("limit::", limit);
    // console.log("isFetching ::: ", filterState.isFetching)
    // console.log("isFetching ::: ", filterState.isFetching)
    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc2/3?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("data:::", result);
        dispatch({type: "RESET_TAB3"});
        dispatch({ type: "FETCH_TAB3_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc2/3?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=monthly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB3_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB3_STATUS", status: true});
            }
        }else {
            dispatch({ type: "TAB3_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB3", error: error.response.data.message });
        // message.error("Sc 2 Tab 3 : Somethime went wrong, Please try again !!!", 20);
        // message.error(error.response.data.message);
    }
}
