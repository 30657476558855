import React, { createContext, useReducer, useEffect, useContext } from "react";
import axios from "axios";

// import { Scenario2Context } from "../../../../context/scenario2/Scenario2Context"

import { FilterContext } from "./FilterContext"
import { message } from "antd";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
    tab1: [],
    tab2: [],
    isFetchingTab1: false,
    isErrorTab1: false,
    isFetchingTab2: false,
    isErrorTab2: false,
    isSubmitClicked: false,
    totalRecordsTab1: 0,
    totalRecordsTab2: 0,
    batchSizeTab1: 0,
    batchSizeTab2: 0,
    offsetTab1: 0,
    limitTab1: 50,
    columnTab1: [],
    columnTab2: [],
    isTab1Fetched: false,
    isTab2Fetched: false,
    errorMessage: "",
};

let reducer = (state, action) => {
    // console.log(action)
    switch (action.type) {
        case "HANDLE_SUBMIT":
            fetchTab1APiData(action.filterState, action.dispatch)

            fetchTab2APiData(action.filterState, action.dispatch)

            return {
                ...state,
                isFetchingTab1: true,
                isFetchingTab2: true,
            }

        case "FETCH_DATA":
            let temp = state.tab1
            // console.log(temp)
            temp.push(...action.payload.data)
            
            return {
                ...state,
                tab1: [...new Set(temp)],
                isFetchingTab1: false,
                totalRecordsTab1: action.payload.totalRecords,
                batchSizeTab1: action.payload.batchSize,
                columnTab1: action.payload.columns,
            };

        case "FETCH_TAB2_DATA":
            let temp2 = state.tab2
            // console.log(temp)
            temp2.push(...action.payload.data)
            return {
                ...state,
                tab2: [...new Set(temp2)],
                isFetchingTab2: false,
                totalRecordsTab2: action.payload.totalRecords,
                batchSizeTab2: action.payload.batchSize,
                columnTab2: action.payload.columns
            };

        case "FETCH_ERROR_TAB1":
            return {
                ...state,
                isErrorTab1: true,
                isFetchingTab1: false,
                isTab1Fetched: false,
                tab1: [],
                columnTab1: [],
                totalRecordsTab1: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB2":
            return {
                ...state,
                isErrorTab2: true,
                isFetchingTab2: false,
                isTab2Fetched: false,
                tab2: [],
                columnTab2: [],
                totalRecordsTab2: 0,
                errorMessage: action.error,
            };
        
        case "RESET_TAB1":
            return {
                ...state,
                isFetchingTab1: false,
                isErrorTab1: false,
                isTab1Fetched: false,
                tab1: [],
                totalRecordsTab1: 0,
                batchSizeTab1: 0,
                columnTab1: [],
            };
        case "RESET_TAB2":
            return {
                ...state,
                isFetchingTab2: false,
                isErrorTab2: false,
                isTab2Fetched: false,
                tab2: [],
                totalRecordsTab2: 0,
                batchSizeTab2: 0,
                columnTab2: [],
            };
        case "TAB1_STATUS":
            return {
                ...state,
                isTab1Fetched: action.status
            };
        case "TAB2_STATUS":
            return {
                ...state,
                isTab2Fetched: action.status
            };
        
        default:
            return;
    }
};

export const Scenario8Context = createContext(INITIAL_STATE);

export const Scenario8Provider = (props) => {
    // console.log("SC8 Context called !!!")
    const { filterState } = useContext(FilterContext);
    // console.log("Transactions filter state:::", filterState);

    const [sc8State, sc8Dispatch] = useReducer(reducer, INITIAL_STATE);


    return (
        <Scenario8Context.Provider value={{ sc8State, sc8Dispatch }}>
            {props.children}
        </Scenario8Context.Provider>
    );
};

const fetchTab1APiData = async (filterState, dispatch) => {
    // console.log("sc8 Fetch Tab1 Api Data :::")
    // console.log("sc6n7  Filter State:::", filterState)
    offset = 0;
    // console.log("sc8 url : "+ `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}sc8/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=Quarterly&limit=${limit}&offset=${offset}` )
    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc8/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=Quarterly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc8  data:::", result);
        dispatch({type: "RESET_TAB1"});
        dispatch({ type: "FETCH_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc8/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=Quarterly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB1_STATUS", status: true});
            }
        }else {
            dispatch({ type: "TAB1_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB1", error: error.response.data.message });
        // message.error("Sc 8 Tab 1 : Somethime went wrong, Please try again !!!", 20);
        // message.error(error.response.data.message);
    }
}

const fetchTab2APiData = async (filterState, dispatch) => {
    // console.log("sc8 Fetch Tab2 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc8/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=Quarterly&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc6n7  data:::", result);
        dispatch({type: "RESET_TAB2"});
        dispatch({ type: "FETCH_TAB2_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc8/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=Quarterly&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB2_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB2_STATUS", status: true});
            }
        }else {
            dispatch({ type: "TAB2_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB2", error: error.response.data.message });
        // message.error("Sc 8 Tab 2 : Somethime went wrong, Please try again !!!", 20);
        // message.error(error.response.data.message);
    }
}


