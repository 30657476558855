import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
// import { ExcelExportModule, exportMultipleSheetsAsExcel } from 'ag-grid-enterprise/excel-export';
import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";
import dal from "../../../../dal/api-call";
import "antd/dist/antd.css";
import { Tabs, Row, Select, Col } from "antd";
import { message } from "antd"
import "bootstrap/dist/css/bootstrap.min.css";

import { FilterContext } from "../../../../context/FilterContext";
import { Scenario10Context } from "../../../../context/Scenario10Context"
import { FilterComponent } from "../../../../components/Layout/TRScenarios/FilterComponent"

import { SC10Tab1, SC10Tab2 } from "./Scenario10Tables"

import { } from "react-bootstrap";

const { Option } = Select;

const { TabPane } = Tabs;

const callback = (key) => {
    // console.log(key);
};

const Scenario10 = () => {
    let userIdentity = null;
    const { filterState, filterDispatch } = useContext(FilterContext);
    const { sc10State } = useContext(Scenario10Context);

    const exportButton = <Button type="primary" variant="primary" size="sm" style={{marginBottom: "4px"}} onClick={(e) => exportToCSV()}>Export</Button>;
    
    console.log("SC10 called!!!")

    // useEffect(() => {
    //     dal.request("https://compliance.dev.api.pe.tvx-test.cloud/ui/v1/user/identity")
    //     .then((response) => {
    //         console.log("Auth response :: ", response)
    //         userIdentity = response.data;
    //         localStorage.setItem("userIdentity", JSON.stringify(userIdentity));
    //     })
    //     .catch((error) => console.log("Auth error :: ", error));
        
    // });
    

    let fileName = "SC10-Split Transaction Activity - "+filterState.country+"_report";
    const exportToCSV = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        try{
            if(sc10State.isTab1Fetched && sc10State.isTab2Fetched){
                let transData = JSON.parse(JSON.stringify(sc10State.tab1));
                let transDataTab2 = JSON.parse(JSON.stringify(sc10State.tab2));

                transData.map( el=>{
                    delete el["index"];
                    delete el["key"];
                });
                transDataTab2.map( el=>{
                    delete el["index"];
                    delete el["key"];
                });

                const wb = XLSX.utils.book_new();

                const ws = XLSX.utils.json_to_sheet(transData);
                const ws2 = XLSX.utils.json_to_sheet(transDataTab2);
                // const wb = { Sheets: { data: ws }, SheetNames: ["Raw Data"] };
                // console.log("Trans Data ::: ",transData);
                

                XLSX.utils.book_append_sheet(wb, ws, "Raw Data");
                XLSX.utils.book_append_sheet(wb, ws2, "Potential Split Transactions");

                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            
                const fileData = new Blob([excelBuffer], { type: fileType });
            
                FileSaver.saveAs(fileData, fileName + fileExtension);
            } else {
                throw new Error("Please wait while all tabs are loaded !!!")
            }
        }catch(error){
            message.info(error.message)
        }

      };

    return (
        <>  
            {/* {console.log("Scenario 10 :: ", sc10State.isErrorTab1, " --- ",  sc10State.isErrorTab2 , " --- ", sc10State.errorMessage)} */}
            <Row>
                {sc10State.isErrorTab1 || sc10State.isErrorTab2 ? (
                    <Col span={24}>
                        <div className="error" style={{color: "#842029", backgroundColor: "#f8d7da", borderColor: "#f5c2c7", paddingLeft: "10px", alignContent: "center", display:"flex", justifyContent: "center"}}>
                            {sc10State.errorMessage}
                        </div>
                    </Col>
                ): (
                    <div>
                        
                    </div>
                )}
                
            </Row>
            <Row>
                <Col span={4}>
                    <FilterComponent />
                </Col>
                <Col span={20}>
                    <Tabs
                        type="card"
                        defaultActiveKey="1"
                        onChange={callback}
                        style={{ paddingTop: "4px" }}
                        tabBarExtraContent={exportButton} >

                        <TabPane tab="Raw Data" key="1">
                            <SC10Tab1 />
                        </TabPane>
                        <TabPane tab="Potential Split Transactions" key="2">
                            <SC10Tab2 />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}

export default Scenario10

