import React, { createContext, useReducer, useEffect, useContext } from "react";
import axios from "axios";

// import { Scenario2Context } from "../../../../context/scenario2/Scenario2Context"

import { FilterContext } from "./FilterContext"
import { message } from "antd";

let limit = 500;
let offset = 0;

let INITIAL_STATE = {
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
    tab5: [],
    tab6: [],
    tab7: [],
    isFetchingTab1: false,
    isErrorTab1: false,
    isFetchingTab2: false,
    isErrorTab2: false,
    isFetchingTab3: false,
    isErrorTab3: false,
    isFetchingTab4: false,
    isErrorTab4: false,
    isFetchingTab5: false,
    isErrorTab5: false,
    isFetchingTab6: false,
    isErrorTab6: false,
    isFetchingTab7: false,
    isErrorTab7: false,
    isSubmitClicked: false,
    totalRecordsTab1: 0,
    totalRecordsTab2: 0,
    totalRecordsTab3: 0,
    totalRecordsTab4: 0,
    totalRecordsTab5: 0,
    totalRecordsTab6: 0,
    totalRecordsTab7: 0,
    batchSizeTab1: 0,
    batchSizeTab2: 0,
    batchSizeTab3: 0,
    batchSizeTab4: 0,
    batchSizeTab5: 0,
    batchSizeTab6: 0,
    batchSizeTab7: 0,
    offsetTab1: 0,
    limitTab1: 50,
    columnTab1: [],
    columnTab2: [],
    columnTab3: [],
    columnTab4: [],
    columnTab5: [],
    columnTab6: [],
    columnTab7: [],
    isTab1Fetched: false,
    isTab2Fetched: false,
    isTab3Fetched: false,
    isTab4Fetched: false,
    isTab5Fetched: false,
    isTab6Fetched: false,
    isTab7Fetched: false,
    errorMessage: "",
};

let reducer = (state, action) => {
    // console.log(action)
    switch (action.type) {
        case "HANDLE_SUBMIT":
            fetchTab1APiData(action.filterState, action.dispatch)

            fetchTab2APiData(action.filterState, action.dispatch)

            fetchTab3APiData(action.filterState, action.dispatch)

            fetchTab4APiData(action.filterState, action.dispatch)

            fetchTab5APiData(action.filterState, action.dispatch)

            fetchTab6APiData(action.filterState, action.dispatch)

            fetchTab7APiData(action.filterState, action.dispatch)

            return {
                ...state,
                isFetchingTab1: true,
                isFetchingTab2: true,
                isFetchingTab3: true,
                isFetchingTab4: true,
                isFetchingTab5: true,
                isFetchingTab6: true,
                isFetchingTab7: true,
            }

        case "FETCH_DATA":
            let temp = state.tab1
            temp.push(...action.payload.data)
            
            return {
                ...state,
                tab1: [...new Set(temp)],
                isFetchingTab1: false,
                totalRecordsTab1: action.payload.totalRecords,
                batchSizeTab1: action.payload.batchSize,
                columnTab1: action.payload.columns,
            };

        case "FETCH_TAB2_DATA":
            let temp2 = state.tab2
            temp2.push(...action.payload.data)
            return {
                ...state,
                tab2: [...new Set(temp2)],
                isFetchingTab2: false,
                totalRecordsTab2: action.payload.totalRecords,
                batchSizeTab2: action.payload.batchSize,
                columnTab2: action.payload.columns
            };

        case "FETCH_TAB3_DATA":
            let temp3 = state.tab3
            temp3.push(...action.payload.data)
            return {
                ...state,
                tab3: [...new Set(temp3)],
                isFetchingTab3: false,
                totalRecordsTab3: action.payload.totalRecords,
                batchSizeTab3: action.payload.batchSize,
                columnTab3: action.payload.columns
            };
        case "FETCH_TAB4_DATA":
            let temp4 = state.tab4
            temp4.push(...action.payload.data)
            return {
                ...state,
                tab4: [...new Set(temp4)],
                isFetchingTab4: false,
                totalRecordsTab4: action.payload.totalRecords,
                batchSizeTab4: action.payload.batchSize,
                columnTab4: action.payload.columns
            };
        case "FETCH_TAB5_DATA":
            let temp5 = state.tab5
            temp5.push(...action.payload.data)
            return {
                ...state,
                tab5: [...new Set(temp5)],
                isFetchingTab5: false,
                totalRecordsTab5: action.payload.totalRecords,
                batchSizeTab5: action.payload.batchSize,
                columnTab5: action.payload.columns
            };
        case "FETCH_TAB6_DATA":
            let temp6 = state.tab6
            temp6.push(...action.payload.data)
            return {
                ...state,
                tab6: [...new Set(temp6)],
                isFetchingTab6: false,
                totalRecordsTab6: action.payload.totalRecords,
                batchSizeTab6: action.payload.batchSize,
                columnTab6: action.payload.columns
            };
        case "FETCH_TAB7_DATA":
            let temp7 = state.tab7
            temp7.push(...action.payload.data)
            return {
                ...state,
                tab7: [...new Set(temp7)],
                isFetchingTab7: false,
                totalRecordsTab7: action.payload.totalRecords,
                batchSizeTab7: action.payload.batchSize,
                columnTab7: action.payload.columns
            };

        case "FETCHING":
            return {
                ...state,
                isFetchingTab1: true,
                isFetchingTab2: true,
                isFetchingTab3: true,
                isSubmitClicked: true,
            };

        case "FETCH_ERROR_TAB1":
            return {
                ...state,
                isErrorTab1: true,
                isFetchingTab1: false,
                isTab1Fetched: false,
                tab1: [],
                columnTab1: [],
                totalRecordsTab1: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB2":
            return {
                ...state,
                isErrorTab2: true,
                isFetchingTab2: false,
                isTab2Fetched: false,
                tab2: [],
                columnTab2: [],
                totalRecordsTab2: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB3":
            return {
                ...state,
                isErrorTab3: true,
                isFetchingTab3: false,
                isTab3Fetched: false,
                tab3: [],
                columnTab3: [],
                totalRecordsTab3: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB4":
            return {
                ...state,
                isErrorTab4: true,
                isFetchingTab4: false,
                isTab4Fetched: false,
                tab4: [],
                columnTab4: [],
                totalRecordsTab4: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB5":
            // console.log("FETCH_ERROR_TAB5");
            return {
                ...state,
                isErrorTab5: true,
                isFetchingTab5: false,
                isTab5Fetched: false,
                tab5: [],
                columnTab5: [],
                totalRecordsTab5: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB6":
            return {
                ...state,
                isErrorTab6: true,
                isFetchingTab6: false,
                isTab6Fetched: false,
                tab6: [],
                columnTab6: [],
                totalRecordsTab6: 0,
                errorMessage: action.error,
            };
        case "FETCH_ERROR_TAB7":
            return {
                ...state,
                isErrorTab7: true,
                isFetchingTab7: false,
                isTab7Fetched: false,
                tab7: [],
                columnTab7: [],
                totalRecordsTab7: 0,
                errorMessage: action.error,
            };
        
        case "RESET_TAB1":
            return {
                ...state,
                isErrorTab1: false,
                isFetchingTab1: false,
                isTab1Fetched: false,
                tab1: [],
                totalRecordsTab1: 0,
                batchSizeTab1: 0,
                columnTab1: [],
            };
        case "RESET_TAB2":
            return {
                ...state,
                isErrorTab2: false,
                isFetchingTab2: false,
                isTab2Fetched: false,
                tab2: [],
                totalRecordsTab2: 0,
                batchSizeTab2: 0,
                columnTab2: [],
            };
        case "RESET_TAB3":
            return {
                ...state,
                isErrorTab3: false,
                isFetchingTab3: false,
                isTab3Fetched: false,
                tab3: [],
                totalRecordsTab3: 0,
                batchSizeTab3: 0,
                columnTab3: [],
            };
        case "RESET_TAB4":
            return {
                ...state,
                isErrorTab4: false,
                isFetchingTab4: false,
                isTab4Fetched: false,
                tab4: [],
                totalRecordsTab4: 0,
                batchSizeTab4: 0,
                columnTab4: [],
            };
        case "RESET_TAB5":
            return {
                ...state,
                isErrorTab5: false,
                isFetchingTab5: false,
                isTab5Fetched: false,
                tab5: [],
                totalRecordsTab5: 0,
                batchSizeTab5: 0,
                columnTab5: [],
            };
        case "RESET_TAB6":
            return {
                ...state,
                isErrorTab6: false,
                isFetchingTab6: false,
                isTab6Fetched: false,
                tab6: [],
                totalRecordsTab6: 0,
                batchSizeTab6: 0,
                columnTab6: [],
            };
        case "RESET_TAB7":
            return {
                ...state,
                isErrorTab7: false,
                isFetchingTab7: false,
                isTab7Fetched: false,
                tab7: [],
                totalRecordsTab7: 0,
                batchSizeTab7: 0,
                columnTab7: [],
            };
        case "TAB1_STATUS":
            return {
                ...state,
                isTab1Fetched: action.status
            }
        case "TAB2_STATUS":
            return {
                ...state,
                isTab2Fetched: action.status
            }
        case "TAB3_STATUS":
            return {
                ...state,
                isTab3Fetched: action.status
            }
        case "TAB4_STATUS":
            return {
                ...state,
                isTab4Fetched: action.status
            }
        case "TAB5_STATUS":
            return {
                ...state,
                isTab5Fetched: action.status
            }
        case "TAB6_STATUS":
            return {
                ...state,
                isTab6Fetched: action.status
            }
        case "TAB7_STATUS":
            return {
                ...state,
                isTab7Fetched: action.status
            }

        default:
            return;
    }
};

export const Scenario1n3Context = createContext(INITIAL_STATE);

export const Scenario1n3Provider = (props) => {
    // console.log("SC1n3 Context called !!!")
    const { filterState } = useContext(FilterContext);
    // console.log("Transactions filter state:::", filterState);

    const [sc1n3State, sc1n3Dispatch] = useReducer(reducer, INITIAL_STATE);

    return (
        <Scenario1n3Context.Provider value={{ sc1n3State, sc1n3Dispatch }}>
            {props.children}
        </Scenario1n3Context.Provider>
    );
};

const fetchTab1APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab1 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;
    // console.log("URL ::",`${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}sc1n3/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`)
    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB1"});
        dispatch({ type: "FETCH_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/1?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_DATA", payload: result});
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                // console.log("1. TAB1_STATUS", offsetCounter)
                dispatch({ type: "TAB1_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB1_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error.response.data);
        dispatch({ type: "FETCH_ERROR_TAB1", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 1 :: " + error.response.data.message, 20);
        // message.error("Sc 1 and 3 Tab 1 : Somethime went wrong, Please try again !!!", 10);
    }
}

const fetchTab2APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab2 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB2"});
        dispatch({ type: "FETCH_TAB2_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/2?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB2_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB2_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB2_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB2", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 2 :: " + error.response.data.message, 20);
        // message.error("Sc 1 and 3 Tab 2 : Somethime went wrong, Please try again !!!", 10);
    }
}

const fetchTab3APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab3 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/3?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB3"});
        dispatch({ type: "FETCH_TAB3_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/3?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB3_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB3_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB3_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB3", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 3 : Somethime went wrong, Please try again !!!", 10);
        // message.error("Sc 1 and 3 Tab 3 :: " + error.response.data.message, 20);
    }
}

const fetchTab4APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab4 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/4?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB4"});
        dispatch({ type: "FETCH_TAB4_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/4?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB4_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB4_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB4_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB4", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 4 : Somethime went wrong, Please try again !!!", 10);
        // message.error("Sc 1 and 3 Tab 4 :: " + error.response.data.message, 20);
    }
}

const fetchTab5APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab5 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/5?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB5"});
        dispatch({ type: "FETCH_TAB5_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/5?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB5_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB5_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB5_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("FETCH_ERROR_TAB5 errror::", error.response.data.message);
        dispatch({ type: "FETCH_ERROR_TAB5", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 5 : Somethime went wrong, Please try again !!!", 10);
        // message.error("Sc 1 and 3 Tab 5 :: " + error.response.data.message, 20);
    }
}

const fetchTab6APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab6 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/6?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB6"});
        dispatch({ type: "FETCH_TAB6_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/6?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB6_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB6_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB6_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB6", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 6 : Somethime went wrong, Please try again !!!", 10);
        // message.error("Sc 1 and 3 Tab 6 :: " + error.response.data.message, 20);
    }
}

const fetchTab7APiData = async (filterState, dispatch) => {
    // console.log("sc1n3 Fetch Tab7 Api Data :::")
    // console.log("sc1n3  Filter State:::", filterState)
    offset = 0;

    try {
        const response = await axios.get(
                            `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/7?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                        );
        let result = response.data;
        // console.log("sc1n3  data:::", result);
        dispatch({type: "RESET_TAB7"});
        dispatch({ type: "FETCH_TAB7_DATA", payload: result });
        // offset = offset + 1;
        let offsetCounter = 0;
        if (offset <= result.totalRecords) {
            // console.log("off set is less than total records");
            // console.log("Offset 2 :::", offset);
            // console.log("Batch size :::", result.batchSize)
            while (offsetCounter <= result.batchSize) {
                // console.log("Offset counter :::", offsetCounter)
                offsetCounter = offsetCounter === 0 ? offsetCounter + 1 : offsetCounter;
                offset = offsetCounter * limit;
                // console.log("offset::", offset);
                const response = await axios.get(
                `${process.env.REACT_APP_COMPLIANCE_API_BASE_PATH}scenario/sc1n3/7?region=${filterState.geo}&country=${filterState.country}&year=${filterState.year}&month=${filterState.month}&period=${filterState.periodCat}&week_no=${filterState.period}&limit=${limit}&offset=${offset}`
                );
                const result = response.data;
                dispatch({ type: "FETCH_TAB7_DATA", payload: result });
                offsetCounter = offsetCounter + 1;
            }
            if (offsetCounter > result.batchSize) {
                dispatch({ type: "TAB7_STATUS", status: true});
            }
        }else {
            // console.log("2. TAB1_STATUS")
            dispatch({ type: "TAB7_STATUS", status: true});
        }
        
        return result;
    } catch (error) {
        // console.log("errror::", error);
        dispatch({ type: "FETCH_ERROR_TAB7", error: error.response.data.message });
        // message.error("Sc 1 and 3 Tab 7 : Somethime went wrong, Please try again !!!", 10);
        // message.error("Sc 1 and 3 Tab 7 :: " + error.response.data.message, 20);
    }
}
