
import queryString from 'query-string';
import Cookies from 'js-cookie'
import {
    useLocation,
    useHistory
} from "react-router-dom";

const cookieConfigs = {
    expires: new Date(new Date().getTime() + 240 * 60 * 1000),
    // secure: true
}
const SetupCode = () => {
    let history = useHistory();
    const location = useLocation();
    let params = queryString.parse(location.search);
    
    if(params.code && params.state === Cookies.get('state')){
        let expiresIn = new Date().getTime() + 240 * 60 * 1000;
        Cookies.set('code',params.code,cookieConfigs)
        Cookies.set('expiresIn',expiresIn,cookieConfigs)
        Cookies.remove('state');
        setExpiration(240);
        history.push("/dashboard");
        //set up expiry time
    }else{
        history.push("/login");
    }

    return null;
}

function setExpiration(expirationInMin = 15) {

    let code = Cookies.get('code');
    let expiresIn = Cookies.get('expiresIn');

    let isCookieExpiryTime = (expiresIn - new Date().getTime())/60000

    if(isNaN(isCookieExpiryTime)) return false;
    if(isCookieExpiryTime < 2){
        
        //let expires = new Date(new Date().getTime() + 240 * 60 * 1000)
        let expires = new Date();
        alert('seeting from new code');
        expires.setHours(expires.getHours() + 4);

        Cookies.set('code',code,{
            expires
        });
        Cookies.set('expiresIn',new Date().getTime(),{
            expires
        });
    }

    return true;
  }
export default SetupCode;