import React, { useReducer, useContext } from "react";
import moment from "moment";

const initialState = {
  geo: "",
  country: "",
  year: "",
  month: "",
  periodCat: "",
  period: "",
  date: "",
  countries: {
    UK: ["Aberdeen Airport", "Belfast City Airport", "Bournemouth Airport", "Doncaster Airport", "Dover Ferry Ships", "Dover Ferry Terminal", "East Midlands Airport", "Eurotunnel", "Exeter Airport", "Glasgow Airport", "Heathrow Terminal 1", "Heathrow Terminal 2", "Heathrow Terminal 3", "Heathrow Terminal 4", "Heathrow Terminal 5", "Heathrow VAT", "Leeds Bradford Airport", "Liverpool Airport", "London City Airport", "London Retail", "Manchester", "Newcastle Airport", "Manchester VAT", "Portsmouth Ferryport"],
    Europe: ["Belgium", "Czech Republic", "France ADP", "France BTSA", "Germany", "Italy", "Switzerland"],
    MET: ["Bahrain", "Oman", "Turkey 2", "Turkey 1", "UAE"],
    SA: ["South Africa-Inland", "South Africa-Coastal"],
    TAS: ["Tesco", "Asda"],
    NAM: ["Panama", "Canada", "GUAM US", "East US", "South US", "MidWest US", "West US"]
  },
  
  geoFlag: false,
  countryFlag: false,
  yearFlag: false,
  monthFlag: false,
  isSubmitButtonDisable: true,

  // isFetching: true
};

let reducer = (state, action) => {
  switch (action.type) {
    case "GEO_CHANGE":
      return {
        ...state,
        geo: action.value,
        geoFlag: true,
        isSubmitButtonDisable: false,
      };

    case "COUNTRY_CHANGE":
      state.country= ""
      return {
        ...state,
        country: action.value,
        countryFlag: true,
        isSubmitButtonDisable: false,
      };
    case "YEAR_CHANGE":
      return {
        ...state,
        year: action.value,
        yearFlag: true,
        isSubmitButtonDisable: false,
      };
    case "MONTH_CHANGE":
      return {
        ...state,
        month: action.value,
        monthFlag: true,
        isSubmitButtonDisable: false,
      };
    case "PERIOD_CAT_CHANGE":
      return {
        ...state,
        periodCat: action.value,
        isSubmitButtonDisable: false,
      };
    case "PERIOD_CHANGE":
      return {
        ...state,
        period: action.value,
        isSubmitButtonDisable: false,
      };
    case "DATE_CHANGE":
      // console.log("DATE_CHANGE", action.value.format("YYYY-MM-DD"))
      return{
        ...state,
        date: action.value ? action.value.format("YYYY-MM-DD") : null,
        isSubmitButtonDisable: false,
      };
    case "HANDLE_SUBMIT_STATE":
      return{
        ...state,
        date: action.value,
        isSubmitButtonDisable: true,
      };

    default:
      return;
  }
};



export const FilterContext = React.createContext(initialState);

export const FilterProvider = (props) => {

  const [filterState, filterDispatch] = useReducer(reducer, initialState);

  return (
    <FilterContext.Provider value={{ filterState, filterDispatch }}>
      {props.children}
    </FilterContext.Provider>
  );
};
